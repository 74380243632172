<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">技术经营部</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">技术经营部主要职责：</span></b>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">主要承担公司目标成本及责任成本管理，技术资料、项目报奖、报优等成果归档；资质、体系认证、信用报告、技术委员会、专家库、标准编制、招投标等维护管理，跟踪市场及监控项目动态；法律文件合法性审核及管理工作、法律纠纷处理、投资并购等法律风险管理。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;"><b>副部长：丁誉恒（负责人）、吴雷</b></span> 
        </p>
        <img width="80%" style="margin: 10px 10% 0" src="../../assets/htmlimg/“综合部”大.jpeg" alt="" />
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>