<template>
  <div class = "footer_container hidden-xs-only">
    <div class = "footer_content">
      <el-row class="content" style="height: 100%;">
        <el-col :span="18" style="height: 100%;">
          <div class = "footer_logo">
            <div class="footer-box-left">
              <div class="logo-img"><img :src = "logo_url" alt = ""></div>
            </div>
            <div class="footer-box-right">
              <div class="line">辽宁省大连市中山区人民路23号虹源大厦(3408室)</div>
              <div class="line">T：0411-83721200<span style="margin-left: 10px;">E：dlgtkj@dtspd.com</span></div>
            </div>
          </div>
        </el-col>
        <el-col :span="6" style="height: 100%;">
          <div class = "focus_us">
            <img :src = "qr" alt = "">
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="footer-text">版权所有：大连市国土空间规划设计有限公司<a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left: 30px;">辽ICP备2021002271号-1</a></div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      footerHeight: '',
      curWidth: '',
      logo_url: require('../../assets/img/index/logo_footer.png'),
      qr: require('../../assets/img/focus/qr_weixin.jpeg'),
    }
  },
  methods: {
    // setFooterHeight () {
    //   this.curWidth = window.innerWidth
    //   if (this.curWidth <= 991) {
    //     this.footerHeight = 570 + 'px'
    //   } else {
    //     this.footerHeight = 280 + 'px'
    //   }
    // }
  },
  created () {
    // 页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    // window.addEventListener('resize', this.setFooterHeight)
    // this.setFooterHeight()
  }
}
</script>

<style lang = "scss" scoped>
*{
  margin: 0;
  padding: 0;
}
h2{
  font-size: 100%;
  font-weight: 400;
  font-variant: normal;
}
.footer_container {
  position: relative;
  width: 100%;
  background: #373838;
}

.footer-text {
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  a {
    color: #fff;
  }
}

.footer_content {
  width: 80%;
  height: 180px;
  color: #fff;
  margin: 0 10%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  h2 {
    font-size: 14px;
    line-height: 22px;
    margin: 4px 0;
    color: #909399;
  }

  .content {
    width: 100%;
    color: #d2d3d4;

    h2 {
      text-align: center;
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 4px;
    }

  }

  .focus_us {
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100px;
      width: 100px;
    }

    .media {
      margin-top: 18px;
      display: flex;
    }

    span {
      margin-right: 18px;
      flex: 0 0 auto;
    }

    .icon {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }
  }

  .footer_logo {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .footer-box-left {
      width: 40%;
    }
    .footer-box-right {
      width: 60%;
      font-size: 16px;
      .line {
        padding: 5px 0;
      }
    }
    .logo-img {
        img {
          height: 60px;
        }
      }
      .logo-text{
        position: relative;
        display: inline-block;
        width: 380px;
      }
      .text1{
        font-size: 15px;
        font-weight: bold;
        margin-left: 4px;
        text-align: left;
      }
      .text2{
        font-size: 12px;
        margin-left: 4px;
        text-align: left;
      }
  }
}
</style>
