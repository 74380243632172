<template>
  <div class="hidden-sm-and-up mobile_callme_wrap">
    <div class="logo-img"><img src="../../assets/img/index/logo_footer.png" alt = ""></div>
    <div class="line">辽宁省大连市中山区人民路23号虹源大厦(3408室)</div>
    <div class="line">T：0411-83721200<span style="margin-left: 10px;">E：dlgtkj@dtspd.com</span></div>
    <div class="qr-img"><img src = "../../assets/img/focus/qr_weixin.jpeg" alt = ""></div>
    <div class="footer-text">版权所有：大连市国土空间规划设计有限公司</div>
    <div class="footer-text"><a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备2021002271号-1</a></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.mobile_callme_wrap{
  width: 100%;
  margin-top: 20PX;
  padding: 30PX;
  box-sizing: border-box;
  background: #373838;
  .logo-img {
    text-align: center;
    img {
      width: 80%;
      height: auto;
    }
  }
  .line {
    color: #fff;
    font-size: 18px;
    line-height: 2;
    text-align: center;
  }
  .qr-img {
    text-align: center;
    margin: 50px 0;
    img {
      width: 30%;
    }
  }
  .footer-text {
    color: #fff;
    text-align: center;
  }
}
</style>